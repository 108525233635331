<template>
    <div class="profile_notes">
        <div class="title">
            <h3>Notes</h3>
            <div class="right_side">
                <!-- <button type="button" class="add_btn mr-3" @click="addNote = true"><i class="fas fa-plus"></i>Add Note</button> -->
                <div class="search_panel mr-3">
                    <div class="form-field">
                        <input type="text" name="search" placeholder="Search...">
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <PageItem/>
                <button :class="deleteBox.length ? 'delete active' : 'delete'"><i class="fas fa-trash-alt"></i></button>
            </div>
        </div>
        <!-- <div v-if="addNote" class="edit_section mt-4 mb-3">
            <EditCard :closeBtn="closeBtn" title="Add Note"/>
        </div> -->
        <ul class="card_listing">
            <li>
                <div class="note_card">
                    <div class="note_body">
                        <h4>Add Note:</h4>
                        <textarea name="" id="" cols="30" rows="5">Type your text here...</textarea>
                        <div class="action_wpr p-0 m-0">
                            <button class="btn cancel_btn">Clear</button>
                            <button class="btn save_btn">Save</button>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>Notes:</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="note1" class="checkbox">
                        <input type="checkbox" id="note1" value="note1" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>Notes:</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="note2" class="checkbox">
                        <input type="checkbox" id="note2" value="note2" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>Notes:</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="note3" class="checkbox">
                        <input type="checkbox" id="note3" value="note3" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>Notes:</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="note4" class="checkbox">
                        <input type="checkbox" id="note4" value="note4" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>Notes:</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="note5" class="checkbox">
                        <input type="checkbox" id="note5" value="note5" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>Notes:</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="note6" class="checkbox">
                        <input type="checkbox" id="note6" value="note6" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
        </ul>
        <div v-if="editNote" class="edit_modal">
            <div class="edit_section p-0">
                <div class="form_body">
                    <h2 class="title_header">Edit Note<button class="close_btn" @click="editNote = false"><i class="fas fa-times"></i></button></h2>
                    <div class="form_content">
                        <EditCard :closeBtn="closeBtn" title="Edit Note"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination mt-2">
        <ul>
            <li>1</li>
            <li class="active">2</li>
            <li>3</li>
            <li>4</li>
            <li><i class="fas fa-ellipsis-h"></i></li>
        </ul>
    </div>
</template>

<script>
import PageItem from './PageItem.vue';
import NotesCard from './NotesCard.vue';
import EditCard from './EditCard.vue';
export default{
    name: 'ProfileNotes',
    components:{
        PageItem,
        NotesCard,
        EditCard
    },
    data(){
        return{
            editNote: false,
            deleteBox:[],
            actionObj: [
                {
                    icon: "fa-edit",
                    label: "Edit",
                }
            ]
        }
    },
    methods:{
        closeBtn(){
            this.editNote = false;
        },
        editFunc(){
            this.editNote = true;
        }
    }
}
</script>
