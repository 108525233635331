<template>
    <div class="section_title mb-4">{{title}}
        <div class="dropdown">
            <i class="fas fa-ellipsis-v" @click="noteOption = !noteOption"></i>
            <div class="drp_wrapper" v-if="noteOption" :class="{active : noteOption}">
                <ul>
                    <li @click="emailLibrary = !emailLibrary" :class="{active : emailLibrary}">Import Template</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="editorWpr"><img src="@/assets/images/editor.png"></div>
    <div v-if="emailLibrary">
        <h3>Import Template</h3>
        <div class="edit_section">
            <div class="library_wpr">
                <div class="card_item"><img src="@/assets/images/mail.svg">E-mail</div>
                <div class="library_field setting_wpr">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Category</label>
                            <div class="field_wpr">
                                <select v-model="templateType">
                                    <option disabled selected hidden>Select Category</option>
                                    <option value="Affiliate">Affilliate</option>
                                    <option value="Ascension">Ascension</option>
                                    <option value="Awareness">Awareness</option>
                                    <option value="Daily Email">Daily Email</option>
                                    <option value="Direct Pitch">Direct Pitch</option>
                                    <option value="Engagement">Engagement</option>
                                    <option value="Holiday">Holiday</option>
                                    <option value="Indirect Pitch">Indirect Pitch</option>
                                    <option value="Indoctrination">Indoctrination</option>
                                    <option value="One-Off">One-Off</option>
                                    <option value="Re-engagement">Re-engagement</option>
                                    <option value="Segmentation">Segmentation</option>
                                    <option value="Showcase">Showcase</option>
                                    <option value="Special Offer">Special Offer</option>
                                    <option value="Useful But Incomplete">Useful But Incomplete</option>
                                    <option value="Unassigned">Unassigned</option>
                                </select>
                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                            </div>
                        </div>
                        <div v-if="templateType" class="group_item">
                            <div class="field_wpr">
                                <input type="text" placeholder="Search">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action_wpr p-0">
                <button class="btn cancel_btn" @click="emailLibrary = false">Cancel</button>
                <button class="btn save_btn">Import</button>
            </div>
        </div>
    </div>
    <div class="action_wpr mt-4 p-0">
        <button class="btn cancel_btn" @click="closeBtn">Cancel</button>
        <button class="btn save_btn">Save</button>
    </div>
</template>

<script>
export default {
    name: 'EditCard',
    props:['closeBtn', 'title'],
    data(){
        return{
            noteOption: false,
            emailLibrary: false,
            templateType: '',
        }
    }
}
</script>
